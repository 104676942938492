import React from 'react';
import ImageSlideShow from '../layout/ImageSlideShow';

// u DramaClub.module.css su klase za logo sekcije i slide show slika....
import styling from './DramaClub.module.css';

const Gimzeleni = () => {
  const images = [
    {
      title: "",
      src: "/files/gimzeleni/1.jpg",
      alt: "Слика 1",
      text: ""
    },
    {
      title: "",
      src: "/files/gimzeleni/2.jpg",
      alt: "Слика 2",
      text: ""
    },
    {
      title: "",
      src: "/files/gimzeleni/3.jpg",
      alt: "Слика 3",
      text: ""
    },
    {
      title: "",
      src: "/files/gimzeleni/4.jpg",
      alt: "Слика 4",
      text: ""
    }
  ];

  return (
    <div>
      <div>
        <img src="/files/gimzeleni/gimzelenilogo.png" alt="Гимзелени лого" className={styling.logoSekcije}/>
      </div>
      <div>
        <h3>Еколошка ученичка група "Гимзелени"</h3>
        <br /><br />
        <p>Зовемо се "Гимзелени", постојимо од 2011. године и организујемо пројекте са циљем подизања еколошке свести у нашој школи. Наш рад подржавају Pasch-пројекат и Гете-институт Београд. Да ли знаш да је за разградњу једне жваке потребно 5 година, за распадање тетрапака 100 година, а за распадање пластичне кесе 500 до 1000 година?! Конзерви је потребно 200 до 500 година да се разложи, а стаклу чак 4000 година.</p>
        <p>Да ли имаш времена да чекаш?</p>
        <p>Време је да се нешто предузме!</p>
        <p>УКЉУЧИ СЕ!</p>
        <p>Покушавамо да повежемо различите медије и знање немачког језика са еколошким темама!</p>
        <p>МИ:</p>
        <ol>
          <li>САКУПЉАМО, СОРТИРАМО И РЕЦИКЛИРАМО</li>
          <li>ОД СТАРОГ ПРАВИМО НОВО, нпр. УКРАСЕ ЗА ЈЕЛКУ ОД СТАРИХ СИЈАЛИЦА,ДУГМИЋА,ПОКЛОПАЦА ЗА ТЕГЛЕ...</li>
          <li>ОРГАНИЗУЈЕМО ИЗЛОЖБЕ ЕКОЛОШКИХ ФОТОГРАФИЈА И СТРИПОВА</li>
          <li>СНИМАМО ЕКОЛОШКЕ ФИЛМОВЕ</li>
          <li>ПРАВИМО ЛУТКЕ ОД СТАРИХ ЧАРАПА, ПИШЕМО И ДРАМАТИЗУЈЕМО КРАТКЕ СКЕЧЕВЕ</li>
          <li>СЛАВИМО 22. АПРИЛ , "ДАН ПЛАНЕТЕ ЗЕМЉЕ" СА МУЗИКОМ, ПРЕЗЕНТАЦИЈАМА И ГОСТИМА</li>
          <li>ОРГАНИЗУЈУЕМО ХУМАНИТАРНЕ АКЦИЈЕ И ПОДРЖАВАМО ДЕЦУ КОЈОЈ ЈЕ ПОТРЕБНА ПОМОЋ</li>
          <li>СКЛАПАМО НОВА ПРИЈАТЕЉСТВА</li>
          <li>СЕ ЛЕПО ЗАБАВЉАМО</li>
        </ol>
        <p>Да ли ће наша школа постати боље, чистије и интересантније место за живот зависи, пре свега, од нас самих!!!</p>

        <h3>Више информација</h3>
        <a href="http://blog.pasch-net.de/pasch-global/" className={styling.link} rel="noopener noreferrer nofollow" target="_blank">pasch-global</a>
        <a href="http://blog.pasch-net.de/pasch-journal/Umwelt-Wissenschaft" rel="noopener noreferrer nofollow" target="_blank" className={styling.link}>Umwelt-Wissenschaft</a>
        <a href="https://www.facebook.com/GimzeleniGymgrune/" rel="noopener noreferrer nofollow" target="_blank" className={styling.link}>Facebook страница</a>

        <br />
        <p>Координатор пројекта: проф. Валерија Јона Андрејевић</p>
      </div>
      <div>
        <ImageSlideShow images={images} />
      </div>
    </div>
  );
}

export default Gimzeleni;
