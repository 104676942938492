import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

// preview za svaki predmet koji profesor predaje
import Predmet from './Predmet';

import styling from './ProfesorDetails.module.css';
import {Row, Container, Col} from 'react-bootstrap';

const ProfesorDetails = () => {

    const [profesorData, setProfesorData] = useState(null);
    const [predmetiData, setPredmetiData] = useState(null);

    const {profesorID} = useParams();

    useEffect(() => {
      window.scrollTo(0, 0);

      const path = 'http://62.240.9.17:3003/selectProfesor/' + profesorID;

      axios.get(path)
      .then(response => {
        setProfesorData(response.data[0]);
        if(response.data.length > 1)
          setPredmetiData(Array.from(response.data[1]));
          //console.log(response.data[0]);
      })
      .catch(error => {
        console.log("Грешка: " + error);
        // Error
        //window.location.replace("/notFound");
      });

    }, [profesorID]);

    return (
      <div>
      {
        (profesorData != null) ? (
          <div>
            <Container>
              <Row>
                <Col lg={4} md={6} sm={12} className={[styling.avatarImg, styling.centerAlign].join(" ")}>
                  <img src={profesorData[0].src} alt={profesorData[0].alt} className={styling.avatarImg} />
                </Col>
                <Col lg={8} md={6} sm={12} className={[styling.personalData, styling.centerAlign].join(" ")}>
                  <p className={styling.nameText}>{profesorData[0].ime} {profesorData[0].prezime}</p>
                  <p>{profesorData[0].email}</p>
                  <p>Отворена врата: {profesorData[0].otvorena_vrata}</p>
                </Col>
              </Row>

              <Row className={styling.cv}>
                {profesorData[0].cv}
              </Row>
              <Row className={styling.classes}>
              </Row>
            </Container>
          </div>
        )
        : <div>Дошло је до грешке. Покушајте поново.</div>
      }

      {
        (predmetiData != null) ? (
          <div>
            {
              predmetiData.length ? <div><p className={[styling.nameText, styling.centerAlign, styling.bottomPadding, styling.paddingTop].join(" ")}>Предмети</p></div> : null
            }

            <div>
              {
                predmetiData.length ?
                predmetiData.map((predmet, index) => {
                  return (
                    <Predmet
                      key = {index}
                      data = {predmet}
                    />
                  )
                })
                : null
              }
            </div>
          </div>
        )
        : <div></div>
      }
      <Container>
        <Row>
          <Link className={styling.learnMoreLink} to="/profesori"> Врати се назад </Link>
        </Row>
      </Container>
    </div>
    );
}

export default ProfesorDetails;

/*
ime: 'Владимир',
    prezime: 'Глигоров',
    otvorena_vrata: 'четвртак 09:05-09:55',
    email: 'vladimrg@gimnaziajurospredic.edu.rs',
    cv: 'Студирао на Математичком факултету Универзитета у Београду, смер Информатика. Хобији су бициклизам и Партизан',
    src: '/avatars/male.png',
    alt: 'Слика проф'

*/
