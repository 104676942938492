import React from 'react';

// css za sve sekcije(drama, lingvistika, hor...)
import styling from './DramaClub.module.css';

const LinguisticSection = () => {
  return (
    <div>
      <h3>Лингвистичка секција</h3>
      <p>Лингвистичка секција окупља ученике свих разреда који имају посебна интересовања за језик. Предмет нашег истраживања су разнородне језичке појаве карактеристичне та српски језик, али има и општелингвистичких тема, а у новије време и тема везаних за рачунарску лингвистику.</p>
      <p>Лингвистичка секција наше школе једна је од најуспешнијих у земљи, вишеструко награђивана од Друштва за српски језик и књижевност.
У дугогодишњој традицији рада из ове секције изникли су данашњи професори и асистенти БУ, цењени научници у области језика, али и других научних грана. По њиховом сведочењу ова секција их је увела у научно-истраживачки рад.</p>
      <p>Један од првиx великих пројеката био је састављање Речника жаргона ученика Гимназије ”Урош Предић”, овај пројекат је завршен 2004, а наредне године објављен је у форми књиге (награђени рад).</p>

      <p>Поред овог речника, лингвисти наше школе сачинили су и ”Асоцијативни школски речник” и ”Семантичко-деривациони школски речник” (такође награђени радови).</p>
      <p>Велико истраживање лексике ученика Гимназије резултовало је и радом ”Замена описних придева жаргонским речима” (награђени рад).</p>
      <p>Истраживање функционалног раслојавања види се у радовима: ”Језик женских омладинских гласила” (награђен), ”Језик телевизијских реклама”, ”Језик спортског новинара”.</p>
      <p>Предмет лингвистичких истраживања била су и књижевна дела: ”Језик писца реалисте”, ” Семантичка и граматичка обрада именица циклуса Фуга Сентндреана М. Тешића”.</p>
      <p>Истраживања везана за усвајање лексике: ”Творба речи и усвајање морфолошког система код деце” и ”Развој лексике код деце предшколског узраста”.</p>
      <p>Лингвисти наше школе истраживали су стране речи: ”Англицизми у штампаним медијима”, ”Германизми у српском језику”, ”Германизми у електронском корпусу”, ”Творбене морфеме грчког и латинског порекла”.</p>
      <p>Посебан значај имају радови: ”Полисемија и деривација”, ”Зоонимска метафорика у српском језику” (награђен)  и ”Родна равноправност у језику” својим научним приступом и изведеним закључцима.</p>
      <p>Награђени радови објављени су у гласилу Дртуштва за српски језик и књижевност Свет речи.</p>
      <p>Негујући високо интересовање за језик и трагање за одговорима, гости наше секције били су професори БУ: Рајна Драгићевић, Весна Ломпар, Вељко Брборић, Душка Кликовац и Јасмина Московљевић. Са новосадског Филозофског факултета предавање је држала Гордана Штасни, а гост-предавач са Института за српски језик САНУ био је Слободан Новокмет. Посебна сарадња остварена је са Биљаном Бранић Латиновић из Филолошке гимназије.</p>
      <p>Чланови Лингвистичке секције посећују Филолошки факултет и Институт за српски језик, присуствују предавањима и радионицама.</p>
      <img src="/files/linguisticSection/clanovi.jpg" className={styling.coverImage} alt="Посета чланови Лингвистичке секцијеФилолошком факултету и Институту за српски језик" />
      <p>Лингвистичка секција је двапут добила ПОХВАЛУ за врхунске резултате АП Војводине (2005. и 2006. године), а 2020.  НАГРАДУ  Друштва за српски језик и књижевност као једна од најуспешнијих секција у земљи.</p>
      <p>Лингвистичку секцију од њеноих почетака води Валентина.Паровић.</p>
    </div>
  );
}

export default LinguisticSection;


/*
  <img src="/files/linguisticSection/recnik.jpg" className={styling.coverImage} alt="Речника жаргона ученика Гимназије ”Урош Предић”" />
*/
