import React from 'react';
import styling from './NotFoundPage.module.css';

function NotFoundPage() {
  return (
    <div className={styling.notFound404}>
      <a href="/"><img src="/error404_2.jpg" alt="Грешка 404! Страница није пронађена!" className={styling.image404}/></a>
    </div>
  );
}

export default NotFoundPage;
