// svaka pojedinacna vest (sve o toj temi)
import React, {useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import ImageSlideShow from './ImageSlideShow';

import styling from './newsDetails.module.css';


const NewsDetails = (props) => {

  const [newsData, setNewsData] = useState([]);
  const [paragraphData, setParagraphData] = useState([]);
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);

  const {newsID} = useParams();

  useEffect(() => {
    window.scrollTo(0,0);

    const path = 'http://62.240.9.17:3003/vesti/' + newsID;

    axios.get(path)
    .then(response => {
      setNewsData(response.data[0][0]);

      if (response.data.length > 1){
        setParagraphData(Array.from(response.data[1]));
      }

      if (response.data.length > 2){
        setImages(Array.from(response.data[2]))
      }

      if (response.data.length > 3){
        setFiles(Array.from(response.data[3]))
      }
    })
    .catch(err => {
      console.log(err);
    });
  }, [newsID]);

  const datum =  new Date(newsData.datum_postavljanja);
  const year = datum.getFullYear();
  const month = datum.getMonth() + 1;
  const day = datum.getDate();

  // namestamo putanju do slike za razlicite tipove vesti
  let imagePath = "";

  if (newsData.kategorija === "aktuelno")
    imagePath = "/newsIcons/aktuelno.png";
  if (newsData.kategorija === "maturanti")
    imagePath = "/newsIcons/maturanti2.png";
  if (newsData.kategorija === "takmicenje")
    imagePath = "/newsIcons/takmicenje.webp";


  return (
    <div>
      <h3><img src={imagePath} className={styling.newsImageLogo} alt="" />  {newsData.naslov}</h3>
      <div><p className={styling.date}>{day}.{month}.{year}</p></div>
      <div><p>{newsData.sadrzaj}</p></div>

      <div>
        {
          paragraphData.length ?
          paragraphData.map((paragraph, index) => {return<p key={index}>{paragraph.sadrzaj}</p>})
          :null
        }
      </div>


      <div>
        {
          files.length ?
          files.map((file, index) => {
            return<p key={index}><Link to={file.src} className={styling.link} target="_blank" rel="noopener noreferrer nofollow" ><img src="/pdfIcon.jpg" className={styling.pdfIcon} alt="" />{file.naziv}</Link></p>
          })
          :null
        }
      </div>


      <div>
        {
          images.length ?
          <ImageSlideShow images={images} />
          :null
        }

      </div>


      <Link to="/" className={styling.goBack}>Врати се на почетак</Link>
    </div>
  );
}

export default NewsDetails;
