// pregled svake vesti

import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import styling from './newsPreview.module.css';

const NewsPreview = (props) => {

  const [id, setId] = useState();
  const [title, setTitle] = useState();
  const [date, setDate] = useState();
  const [text, setText] = useState();
  const [category, setCategory] = useState();

  useEffect(() => {
    setId(props.id);
    setTitle(props.title);
    setDate(props.date);
    setText(props.text);
    setCategory(props.category);
  }, [props.id, props.title, props.date, props.text, props.category]);

  // date
  const datum =  new Date(date);
  const year = datum.getFullYear();
  const month = datum.getMonth() + 1;
  const day = datum.getDate();

  const path="/vesti/" + id;


  // namestamo putanju do slike za razlicite tipove vesti
  let imagePath = "";

  if (category === "aktuelno")
    imagePath = "/newsIcons/aktuelno.png";
  if (category === "maturanti")
    imagePath = "/newsIcons/maturanti2.png";
  if (category === "takmicenje")
    imagePath = "/newsIcons/takmicenje.webp";

  return (

    <Card border="light" className={styling.news}>
      <Card.Body className={styling.newsBody}>
      <Card.Header className={styling.newsHeader}><img src={imagePath} className={styling.newsImageLogo} alt="" /> {day}.{month}.{year}.</Card.Header>
        <Card.Title className={styling.newsTitle}>{title}</Card.Title>
        <Card.Text>
          {text}
        </Card.Text>
        <Link to={path} className={styling.learnMore}>Сазнај више...</Link>
      </Card.Body>
    </Card>
  );
}

export default NewsPreview;
