import React from 'react';
import {Link} from 'react-router-dom';

// zbog pdf prikaza fajlova
import styling from './Pravilnici.module.css';

const BioloskiCasopis = () => {
  return (
    <div>
      <div>
        <p>Пред вама се налази први број Школског биолошког двојезичног електронског часописа Гимназије „Урош Предић” у Панчеву.</p>
        <p>Искрено се надамo, да ћете читајући ове стране, сазнати нешто ново, подсетити се наученог, и	да ће се у вама пробудити нове идеје.</p>
      </div>
      <div>
        <div className={[styling.container, styling.containetBetween, styling.wrap].join(" ")}>
          <div className={styling.pdfFile}>
              <Link to="/files/casopisi/bioloski/skolskiBioloskiElektronskiCasopisBroj1.pdf" target="_blank" rel="noopener noreferrer">
                <div className={styling.pdfIcon}>
                  <img src="/pdfIcon.jpg" alt="pdf.icon" />
                </div>
                <div className={styling.pdfText}>
                  Биолошки часопис бр. 1
                </div>
              </Link>
          </div>
            <div className={styling.pdfFile}>
              <Link to="/files/casopisi/bioloski/skolskiBioloskiElektronskiCasopisBroj2.pdf" target="_blank" rel="noopener noreferrer">
                <div className={styling.pdfIcon}>
                  <img src="/pdfIcon.jpg" alt="pdf.icon" />
                </div>
                <div className={styling.pdfText}>
                  Биолошки часопис бр. 2
                </div>
              </Link>
            </div>


            <div className={styling.pdfFile}>
              <Link to="/files/casopisi/bioloski/skolskiBioloskiElektronskiCasopisBroj3.pdf" target="_blank" rel="noopener noreferrer">
                <div className={styling.pdfIcon}>
                  <img src="/pdfIcon.jpg" alt="pdf.icon" />
                </div>
                <div className={styling.pdfText}>
                  Биолошки часопис бр. 3
                </div>
              </Link>
            </div>

          </div>

          

        </div>
      </div>
    
  );
}

export default BioloskiCasopis;
