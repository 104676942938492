import React from 'react';
import {Link} from 'react-router-dom';
//import styling from './TestSchedule.module.css';
import styling from './Pravilnici.module.css';

class TestSchedule extends React.Component {
  render() {
    return (
      <div>
        <h3>Распоред писаних провера</h3>

        <div className={styling.pdfFile}>
            <Link to="/files/rasporedPisanihProvera/rasporedPisanihProvera.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Распоред писаних провера
              </div>
            </Link>
        </div>
      </div>
    )
  }
}


export default TestSchedule;
