import React from 'react';
import News from './news';
//import ImportantLinks from './ImportantLinks';
import {Card} from 'react-bootstrap';

import styling from './sidePanel.module.css';
//import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function SidePanel() {
    return (
      <div className={styling.sidePanel}>
        <div className={styling.smallText}>
          <News />
        </div>
        <div className={styling.linkColor}>
          <Card border="light" className={styling.padding}>

          </Card>
        </div>
      </div>
    );
}

export default SidePanel;

/*<li><a href="https://sr-rs.facebook.com/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={["fab", "facebook"]} /></a> */
/*TODO: linkovi u posebnu componentu*/


/*  <ImportantLinks />*/
