/*import React from 'react';
import axios from 'axios';
import {Table} from 'react-bootstrap';
import styling from './Books.module.css';*/

import React from 'react';
import {Link} from 'react-router-dom';
import styling from './Pravilnici.module.css';

/*class Books extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      books: [],
      err: ""
    }
  }

  componentDidMount() {
    axios.get('http://62.240.9.17:3003/selectUdzbenici')
    .then(response => {
      this.setState({books: Array.from(response.data)});
      console.log(response.data);
    })
    .catch(error => {
      this.setState({err: "Дошло је до грешке. Покушајте касније!"});
    });
  }

  render(){
    const {books, err} = this.state;
    return (
      <div>
        <h3> Уџбеници </h3>
        <Table bordered hover responsive="sm" className={styling.left}>
          <thead>
            <tr>
              <th>Предмет</th><th>Година</th><th>Смер</th><th>Уџбеник</th>
            </tr>
          </thead>
          <tbody>
            {
              books.length ?
              books.map((book, index) => {
                let smer = "природни";
                if (book.smer === 'd')
                  smer = "друштвени"
                else if (book.smer === 'i')
                  smer = "ит";
                return <tr key={index}><td>{book.pnaziv}</td><td>{book.godina}</td><td>{smer}</td><td>{book.unaziv}, {book.izdavac}</td></tr>
              }):
              <tr><td>#</td><td>#</td><td>#</td><td>#</td></tr>
            }
          </tbody>
        </Table>
        {
           err ? <div>{err}</div> : null
        }
      </div>
    )
  }
}*/

const Books = () => {
  return (
    <div className={styling.pravilnici}>
      <h3>Уџбеници</h3>
      <div className={[styling.container, styling.containetBetween, styling.wrap].join(" ")}>

        <div className={styling.pdfFile}>
            <Link to="/files/udzbenici/Udzbenici1-4razred.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Уџбеници 
              </div>
            </Link>
        </div>

        

        <div className={styling.pdfFile}>
            <Link to="/files/udzbenici/UdzbeniciIT.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Уџбеници за ученике са посебним способностима за информатику и рачунарство
              </div>
            </Link>
        </div>



      </div>
    </div>
  );
}

export default Books;
