import React from 'react';
import ImportantLinks from './ImportantLinks';

import {Row, Col} from 'react-bootstrap';
import styling from './footer.module.css';

const Footer = ()=> {

  return (
    <div className={styling.footer}>
      <footer className="page-footer font-small blue pt-4">
        <Row className={styling.nopaddingMargin}>
          <Col sm={12} md={6} lg={6} className={styling.footerInfo}>
            <p><img src="/logo.png" alt="Гимназија лого" /></p>
            <p>Игњата Барајевца 5</p>
            <p>26101 Панчево</p>
            <p>Теl/fax +381 13/ 301 120, 344 483</p>
            <p><a href="http://www.gimnazijaurospredic.edu.rs" className={styling.link}>www.gimnazijaurospredic.edu.rs</a></p>
            <p>
              <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer"><img src="/socialMedia/youtube.png" alt="YouTube" className={styling.socialMediaImg} /></a>
              <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><img src="/socialMedia/instagram.webp" alt="Instagram" className={styling.socialMediaImg} /></a>
              <a href="https://www.facebook.com/people/%D0%93%D0%B8%D0%BC%D0%BD%D0%B0%D0%B7%D0%B8%D1%98%D0%B0-%D0%A3%D1%80%D0%BE%D1%88-%D0%9F%D1%80%D0%B5%D0%B4%D0%B8%D1%9B-%D0%9F%D0%B0%D0%BD%D1%87%D0%B5%D0%B2%D0%BE/100063277146519/" target="_blank" rel="noopener noreferrer"><img src="/socialMedia/facebook.png" alt="Facebook" className={styling.socialMediaImg} /></a>
            </p>
          </Col>

          <Col sm={12} md={6} lg={6} className={[styling.linkColor, styling.center].join(" ")}>
            <ImportantLinks />
          </Col>
        </Row>
        <div className={styling.copyRight}>© 2021 Copyright: VladimirGligorov
        </div>
      </footer>
    </div>
  );
}

export default Footer;

/*

<Col sm={12} md={12} lg={4} className={styling.center}>
  Спонзори, партнери
</Col>

<hr className="clearfix w-100 d-md-none pb-0"/>

<div className="col-md-3 mb-md-0 mb-3">
    <h5 className="text-uppercase">Links</h5>
    <ul className="list-unstyled">
        <li><a href="#!">Link 1</a></li>
        <li><a href="#!">Link 2</a></li>
        <li><a href="#!">Link 3</a></li>
        <li><a href="#!">Link 4</a></li>
    </ul>
</div>

<div className="col-md-3 mb-md-0 mb-3">
    <h5 className="text-uppercase">Links</h5>
    <ul className="list-unstyled">
        <li><a href="#!">Link 1</a></li>
        <li><a href="#!">Link 2</a></li>
        <li><a href="#!">Link 3</a></li>
        <li><a href="#!">Link 4</a></li>
    </ul>
</div>
*/
