import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import styling from './Profesor.module.css';

class Profesor extends React.Component {


  render() {
    //let path = "/profesor/"+ this.props.id;
    return (
      <Link className={styling.learnMoreLink} to={`/profesor/${this.props.id}`}>
      <Card border="light" className={styling.profesor}>
        <Card.Img variant="top" src={this.props.src} alt={this.props.alt} className={styling.avatar}/>
        <Card.Body>
          <Card.Title className={styling.center}>{this.props.name + " " + this.props.lastName}</Card.Title>

            <div className={styling.center}>
              email: {this.props.email}
            </div>
            <div className={styling.center}>
              Отворена врата: {this.props.otvorenaVrata}
            </div>


        </Card.Body>
      </Card>
      </Link>
    )
  }
}

export default Profesor;

/*
<Card.Header className={[styling.center, styling.white].join(' ')}>
  <img src="/female.png" alt="avatarImg" className={styling.avatar}/>
</Card.Header>

  <Link className={styling.learnMoreLink} to={`/profesor/${this.props.id}`}>CV</Link>
*/
