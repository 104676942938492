// preview za svaki predmet
// svaki pojedinacni predmet kod profesora

import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import {Card} from 'react-bootstrap';
import styling from './Predmet.module.css';

const Predmet = (props) => {
  //id, naziv, sadrzaj, godina, smer, fondCasova
  const [data, setData] = useState({});

  useEffect(() => {
    setData(props.data);
  }, [props]);

  let smer = "";
  if (data.smer === "p") smer = "природни смер"
  else
    if (data.smer === "d") smer = "друштвени смер"
    else
      if (data.smer === "i") smer = "ИТ смер";

  /*
    /predmetiIcon/computer.png - Програмирање, Информатика
    TODO: dodaj ikonice za ostale grupe predemeta
  */

  let url = "/opredmetu/"+ data.id;

  return (
    <div className={styling.predmet}>
      <div className={styling.predmetContent}>
        <div className={styling.front}>
          <Card.Img variant="top" src={data.src} alt={data.alt} className={styling.predmetIcon}/>
          <div>
            <div className={styling.bold}>{data.naziv}</div>
            <div>{data.godina} година</div>
            <div>{smer}</div>
          </div>
        </div>
        <div className={styling.back}>
          <div className={styling.learnMoreContainer}>
            <Link className={styling.learnMoreLink} to={url}>Сазнај више...</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Predmet;

/*  <div><p className={[styling.center, styling.bold].join(" ")}>План и програм:</p> <p className={styling.justify}>{data.sadrzaj}</p></div>
  <div className={styling.center}><span className={styling.bold}>Недељни фонд часова:</span>{data.fondCasova}</div>*/
