import React from 'react';
import {Link} from 'react-router-dom';
import styling from './Pravilnici.module.css';


const Classes = () => {
  return (
    <div>

        <div className={styling.pravilnici}>
          <h3>Распоред часова</h3>
          <div className={[styling.container, styling.containetBetween, styling.wrap].join(" ")}>
            <div className={styling.pdfFile}>
                <Link to="/rasporedCasova/profesori.pdf" target="_blank" rel="noopener noreferrer">
                  <div className={styling.pdfIcon}>
                    <img src="/pdfIcon.jpg" alt="pdf.icon" />
                  </div>
                  <div className={styling.pdfText}>
                    Распоред - професори
                  </div>
                </Link>
            </div>

            <div className={styling.pdfFile}>
                <Link to="/rasporedCasova/odeljenja.pdf" target="_blank" rel="noopener noreferrer">
                  <div className={styling.pdfIcon}>
                    <img src="/pdfIcon.jpg" alt="pdf.icon" />
                  </div>
                  <div className={styling.pdfText}>
                    Распоред - разреди
                  </div>
                </Link>
            </div>

            <div className={styling.pdfFile}>
                <Link to="/rasporedCasova/ucionice.pdf" target="_blank" rel="noopener noreferrer">
                  <div className={styling.pdfIcon}>
                    <img src="/pdfIcon.jpg" alt="pdf.icon" />
                  </div>
                  <div className={styling.pdfText}>
                    Распоред - учионице
                  </div>
                </Link>
            </div>

            <div className={styling.pdfFile}>
                <Link to="/rasporedCasova/rasporedDezurstva.pdf" target="_blank" rel="noopener noreferrer">
                  <div className={styling.pdfIcon}>
                    <img src="/pdfIcon.jpg" alt="pdf.icon" />
                  </div>
                  <div className={styling.pdfText}>
                    Распоред - дежурства
                  </div>
                </Link>
            </div>

            <div className={styling.pdfFile}>
                <Link to="/rasporedCasova/rasporedOdeljenskeStaresine.pdf" target="_blank" rel="noopener noreferrer">
                  <div className={styling.pdfIcon}>
                    <img src="/pdfIcon.jpg" alt="pdf.icon" />
                  </div>
                  <div className={styling.pdfText}>
                    Одељенске старешине
                  </div>
                </Link>
            </div>


            <div className={styling.pdfFile}>
                <Link to="/rasporedCasova/rasporedDopunskeDodatne.pdf" target="_blank" rel="noopener noreferrer">
                  <div className={styling.pdfIcon}>
                    <img src="/pdfIcon.jpg" alt="pdf.icon" />
                  </div>
                  <div className={styling.pdfText}>
                    Распоред допунске и додатне наставе
                  </div>
                </Link>
            </div>

          </div>
    </div>
  </div>
  )
}

export default Classes;
