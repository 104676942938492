import React, {useState, useEffect} from 'react';
//import {Link} from 'react-router-dom';

import {Carousel} from 'react-bootstrap';
import styling from './ImageSlideShow.module.css';


const ImageSlideShow = (props) => {
  const [index, setIndex] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    setImages(props.images);
  }, [props.images]);

  const handleSelect = (selectedIndex, e) => {
     setIndex(selectedIndex);
   };


   return (
     <div className={styling.slideShowContainer}>
       <Carousel activeIndex={index} onSelect={handleSelect} interval={3000}>
        {
          images.length ?
          images.map((image, index) => {
            return (<Carousel.Item key={index}>
                      <img src={image.src} alt={image.alt} className={styling.carouselImg}/>
                      <Carousel.Caption>
                        <h3 className={styling.title}> {image.title} </h3>
                        <p className={styling.text}>{image.text}</p>
                      </Carousel.Caption>
                    </Carousel.Item>
                  )
          })
          :null

      }
       </Carousel>
     </div>
   );
}

export default ImageSlideShow;


/*
<Carousel.Item>
  <img
    className="d-block w-100"
    src="holder.js/800x400?text=Third slide&bg=20232a"
    alt="Third slide"
  />


    <h3>Third slide label</h3>
    <p>
      Praesent commodo cursus magna, vel scelerisque nisl consectetur.
    </p>
  </Carousel.Caption>
</Carousel.Item>
*/
