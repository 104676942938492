// detaljan prikaz svakog predmeta
import React, {useEffect, useState}  from 'react';
import {useParams} from 'react-router-dom';
import axios from 'axios';

// stil za sve pededmete
import styling from './Predmet.module.css';
import {Row, Col, Container} from 'react-bootstrap';


const PredmetDetails = (props) => {

  const [data, setData] = useState({});
  const {predmetID} = useParams();


  useEffect(() => {
    window.scrollTo(0, 0);

    const path = 'http://62.240.9.17:3003/opredmetu/' + predmetID;

    axios.get(path)
    .then(response => {
      setData(response.data[0]);
    })
    .catch(err => {
      console.log(err);
    });
  }, [predmetID]);

  //id, naziv, sadrzaj, godina, smer, fondCasova
  let smer = "";
  if (data.smer === "p") smer = "природни смер"
  else
    if (data.smer === "d") smer = "друштвени смер"
    else
      if (data.smer === "i") smer = "ИТ смер";

  return (
    <div>
      <Container>
        <Row>
        <Col lg={4} md={6} sm={12}>
          <img src={data.src} alt={data.alt} className={styling.predmetIconLarge} />
        </Col>
        <Col lg={8} md={6} sm={12}>
          <h3>{data.naziv}</h3>
          <p>{smer}</p>
          <p>Година: {data.godina}</p>
          <p>Недељни фонд часова: {data.fondCasova}</p>
        </Col>
        </Row>
        <Row>
          <p>{data.sadrzaj}</p>
        </Row>
      </Container>
    </div>
  );
}


export default PredmetDetails;
