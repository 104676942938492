import React from 'react';

function Admin() {
  return (
    <div>
      Admin
    </div>
  );
}

export default Admin;
