import React, {useState} from 'react';
import {Tabs, Tab} from 'react-bootstrap';

// svaki casiopis je poseban modul
import BioloskiCasopis from './magazinesBioloski';
import ZaraznoCasopis from './magazinesZarazno';

import styling from './Magazines.module.css';

const Magazines = () => {
  const [key, setKey] = useState('haos');

  return (
    <div className={styling.casopisi}>
      <Tabs
        id="tabsMagazines"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
        >
        <Tab eventKey="haos" title="Хаос" className={styling.casopisiTab}>
          хаос
        </Tab>
        <Tab eventKey="dekadent" title="Декадент" className={styling.casopisiTab}>
          декадент
        </Tab>
        <Tab eventKey="bioloskiCasopis" title="Биолошки часопис" className={styling.casopisiTab}>
          <BioloskiCasopis />
        </Tab>
        <Tab eventKey="zarazno" title="Заразно" className={styling.casopisiTab}>
          <ZaraznoCasopis />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Magazines;
