import React from 'react';
import styling from './Calendar.module.css';

class Calendar extends React.Component {
  render() {
    return (
      <div className={styling.calendarContainer}>
        <img className={styling.calendarImage} src="/kalendar/skolskiKalendar.png" alt="Школски календар" />
      </div>
    )
  }
}

export default Calendar;
