import React from 'react';
import axios from 'axios';
import { Table } from 'react-bootstrap';
import styling from './OpenDoors.module.css';


class OpenDoors extends React.Component {

  constructor(props) {
    super(props);
    this.state ={
      data: [],
      err: ""
    }
  }

  componentDidMount() {
    axios.get('http://62.240.9.17:3003/selectOtvorenaVrata/')
    .then(response => {
      console.log(response.data);
      this.setState({data: Array.from(response.data)});
    })
    .catch(err => {
      this.setState({err: "Дошло је до грешке. Покушајте поново."});
    });
  }

  render() {
    const {data, err} = this.state;
    return (
      <div className={styling.openDoors}>
        <h3>Отворена врата</h3>
        <p> Прва смена - када први и трећи разред наставу похађају од 7:30 до 13:30, а други и четврти разред наставу похађају од 13:40 до 19:40.</p>
        <p> Друга смена - када први и трећи разред наставу похађају од 13:40 до 19:40, а други и четврти разред наставу похађају од 07:30 до 13:30.</p>
        <Table bordered hover responsive="sm">
          <thead>
            <tr>
              <th>#</th><th>Професор</th><th>Термин</th>
            </tr>
          </thead>
          <tbody>
          {
            data.length ?
            data.map((profesor, index) => {return <tr key={index + 1}><td>{index + 1}.</td><td>{profesor.ime} {profesor.prezime}</td><td>{profesor.otvorena_vrata}</td></tr>}):
            null
          }

          </tbody>
        </Table>
        {
          err ? <div>{err}</div> : null
        }
      </div>
    )
  }
}

export default OpenDoors;
