import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import NewsPreview from '../layout/newsPreview';

// u pravilnci.css je izgled pdf kartica sa fajlovima
import styling from './Pravilnici.module.css';
// zbog izgleda vesti vezanih za takmicenje
import stylingNews from '../layout/newsPreview.module.css';

const Graduates = () => {

  const [newsData, setNewsData] = useState([]);
  const [error, setError] = useState("");

  useEffect(()=> {
    axios.get("http://62.240.9.17:3003/selectNewNewsMaturanti")
    .then(response => {
      setNewsData(Array.from(response.data));
    })
    .catch(err => {
      setError(err);
    });

  }, []);

  return (
    <div>
      <h3>Матурски рад</h3>
      <p>На овој страници се налазе упутства за израду матурског рада и како треба да изгледа прва страница истог.
         Молимо вас да их пажљиво прочитате и примените у изради ваших радова. За сва додатна питања можете се увек обратити вашем ментору или
        неком од професора.
      </p>

      <div className={styling.pravilnici}>
        <div className={[styling.container, styling.containCenter, styling.wrap].join(" ")}>
          <div className={styling.pdfFile}>
              <Link to="/files/maturanti/prvastrana.pdf" download="Изглед прве стране матурског рада шк. 2021/22" target="_blank" rel="noopener noreferrer">
                <div className={styling.pdfIcon}>
                  <img src="/pdfIcon.jpg" alt="pdf.icon" />
                </div>
                <div className={styling.pdfText}>
                  Изглед прве стране матурског рада
                </div>
              </Link>
          </div>

          <div className={styling.pdfFile}>
              <Link to="/files/maturanti/Uputstvo_za_pisanje_matruskog_rada.pdf" download="Упутство за писање матурског рада шк. 2021/22" target="_blank" rel="noopener noreferrer">
                <div className={styling.pdfIcon}>
                  <img src="/pdfIcon.jpg" alt="pdf.icon" />
                </div>
                <div className={styling.pdfText}>
                  Упутство за писање матурског рада (Напомена: преузето са сајта Девете београдске гимназије)
                </div>
              </Link>
          </div>
        </div>
      </div>

      <div className={stylingNews.newsSection}>
        {
          newsData.length ?
          newsData.map(news => {
            return <NewsPreview
                      key={news.id}
                      id={news.id}
                      title={news.naslov}
                      date={news.datum_postavljanja}
                      text={news.sadrzaj}
                      category={news.kategorija}
                    />
          })
          :null
        }
        {error ? <div>error</div> : null}
      </div>


    </div>
  )
}

export default Graduates;
