import React from 'react';
import {Link} from 'react-router-dom';
import styling from './Pravilnici.module.css';


const GradesCriteriums = () => {
  return (
    <div className={styling.pravilnici}>
      <h3>Критеријуми оцењивања</h3>
      <div className={[styling.container, styling.containetBetween, styling.wrap].join(" ")}>
        <div className={styling.pdfFile}>
            <Link to="/files/kriterijumiOcenjivanja/matematika.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Математика
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/kriterijumiOcenjivanja/informatika.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Рачунарство и информатика
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/kriterijumiOcenjivanja/fizika.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Физика
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/kriterijumiOcenjivanja/biologija.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Биологија
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/kriterijumiOcenjivanja/geografija.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Географија
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/kriterijumiOcenjivanja/hemija.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Хемија
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/kriterijumiOcenjivanja/istorija.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Историја
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/kriterijumiOcenjivanja/latinski.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Латински језик
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/kriterijumiOcenjivanja/psihologija.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Психологија
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/kriterijumiOcenjivanja/srpski.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Спрски језик и књижевност
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/kriterijumiOcenjivanja/straniJezik.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Страни језици
              </div>
            </Link>
        </div>


      </div>
    </div>
  );
}

export default GradesCriteriums;
