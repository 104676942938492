import React from 'react';
import {Link} from 'react-router-dom';
import styling from './Pravilnici.module.css';

const Pravilnici = () => {

  return (
    <div className={styling.pravilnici}>
      <h3>Статут школе и правилници</h3>
      <div className={[styling.container, styling.containetBetween, styling.wrap].join(" ")}>
        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/СТАТУТ.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Статут школе
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/Правилникоуџбеницима.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
              Правилник о уџбеницима
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/Правилникоусловимаиначинукоришћењасопственогвозила.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
              Правилник о условима и начину коришћења сопственог возила
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/Правилникоуправљањудонацијама.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
              Правилник о управљању донацијама
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/Правилникоупотребимобилногтелефона,електронскогуређајаидругогсредства.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
              Правилник о употреби мобилног телефона, електронског уређаја и другог средства
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/Правилникорасподеличасова.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
              Правилник о расподели часова
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/Правилникопонашањузапосленихуспречавањусукобаинтереса.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
              Правилник о понашању запослених у спречавању сукоба интереса
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/Правилникопоклонима.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
              Правилник о поклонима
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/Правилникокритеијумимазаправдањечасова.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
              Правилник о критеијумима за правдање часова
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/Правилникоканцеларијскомиархивскомпословању.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
              Правилник о канцеларијском и архивском пословању
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/Правилникојавнимнабавкама.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
              Правилник о јавним набавкама
              </div>
            </Link>
        </div>
        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/Правилникозаштииподатакаоличности.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
              Правилник о заштити података о личности
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/Правилниковођењушколскихевиденција.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
              Правилник о вођењу школских евиденција
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/Правилниковаспитно-дисциплинскојиматеријалнојодговорностиученика.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
              Правилник о васпитно-дисциплинској и материјалној одговорности ученика
              </div>
            </Link>
        </div>
        
        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/Pravilnikovanrednomškolovanju.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Правилник о ванредном школовању
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/Pravilnikoodgovornostizaposlenih,konačni.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Правилник о одговорности запослених
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/Pravilnikonagrađivanjuučenika.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Правилник о награђивању ученика
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/Pravilnikoispitima.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Правилник о испитима
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/PoslovnikSavetaroditelja.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Пословник савета родитеља
              </div>
            </Link>
        </div>


        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/PoslovnikoraduŠolskogodbora.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Пословник о раду школског одбора
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/Gimnazijajun2018.Poslovnikucenickogparlamenta.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Пословник ученичког парламента
              </div>
            </Link>
        </div>


       


      </div>
    </div>
  );

}

export default Pravilnici;

/*

*/
