import React from 'react';
import {Link} from 'react-router-dom';

// zbog pdf prikaza fajlova
import styling from './Pravilnici.module.css';

const ZaraznoCasopis = () => {
  return (
    <div>
      <div>
      <p>Часопис „Заразно” настао је током online наставе, наши ђаци саставили су текстове о Гимназији у Панчеву и решили да на часовима предмета Језик медији и култура
          створе електронски часопис у ком би приказали колики је значај наше школе и колико је културних садржаја управо у њој настало. Такође, једна од идеја била је да се скрене
          пажња са пандемије и да се окренемо култури и уметности.</p>
      </div>
      <div>
        <div className={[styling.container, styling.containetBetween, styling.wrap].join(" ")}>
          <div className={styling.pdfFile}>
              <Link to="/files/casopisi/zaraznoCasopis/document.pdf" target="_blank" rel="noopener noreferrer">
                <div className={styling.pdfIcon}>
                  <img src="/pdfIcon.jpg" alt="pdf.icon" />
                </div>
                <div className={styling.pdfText}>
                  Заразно бр. 1
                </div>
              </Link>
          </div>



        </div>
      </div>
    </div>
  );
}

export default ZaraznoCasopis;
