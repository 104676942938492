import React, {useState, useEffect} from 'react';
//import { Link } from 'react-router-dom';
import axios from 'axios';
import NewsPreview from '../layout/newsPreview';

// zbog izgleda vesti vezanih za takmicenje
import stylingNews from '../layout/newsPreview.module.css';

const Competitions = () => {

  const [newsData, setNewsData] = useState([]);
  const [error, setError] = useState("");

  useEffect(()=> {
    axios.get("http://62.240.9.17:3003/selectNewNewsTakmicenja")
    .then(response => {
      setNewsData(Array.from(response.data));
    })
    .catch(err => {
      setError(err);
    });

  }, []);

  return (
    <div className={stylingNews.newsSection}>
      {
        newsData.length ?
        newsData.map(news => {
          return <NewsPreview
                    key={news.id}
                    id={news.id}
                    title={news.naslov}
                    date={news.datum_postavljanja}
                    text={news.sadrzaj}
                    category={news.kategorija}
                  />
        })
        :null
      }
      {error ? <div>error</div> : null}
    </div>
  );
}

export default Competitions;
