import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Predmet from '../layout/Predmet';

// stilovi za sve smerove
import styling from './Smer.module.css';

const DrustveniSmer = () => {

  const [predmeti, setPredmeti] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    axios.get("http://62.240.9.17:3003/predmetiDrustveniSmer")
    .then((response) => {
      setPredmeti(Array.from(response.data));
      //console.log(response.data);
    })
    .catch((error) => {
      setError(error);
      console.log(error);
    });
  }, []);

  // razdvajanje predmeta po godinama
  const predmetiIGodina = predmeti.filter(predmet => predmet.godina === "I");
  const predmetiIIGodina = predmeti.filter(predmet => predmet.godina === "II");
  const predmetiIIIGodina = predmeti.filter(predmet => predmet.godina === "III");
  const predmetiIVGodina = predmeti.filter(predmet => predmet.godina === "IV");

  return (
      <div>
        <h3 className={styling.smerTitle}>Друштвени смер</h3>
        <div className={styling.predmetiPoGodiniContainer}>
          {
            predmetiIGodina.length ?
            (
              predmetiIGodina.map((predmet, index) => {
                return (
                  <Predmet
                    key = {index}
                    data = {predmet}
                    />
                )
              })
            )
            : null
          }
        </div>
        <div className={styling.predmetiPoGodiniContainer}>
          {
            predmetiIIGodina.length ?
            (
              predmetiIIGodina.map((predmet, index) => {
                return (
                  <Predmet
                    key = {index}
                    data = {predmet}
                    />
                )
              })
            )
            : null
          }
        </div>
        <div className={styling.predmetiPoGodiniContainer}>
          {
            predmetiIIIGodina.length ?
            (
              predmetiIIIGodina.map((predmet, index) => {
                return (
                  <Predmet
                    key = {index}
                    data = {predmet}
                    />
                )
              })
            )
            : null
          }
        </div>
        <div className={styling.predmetiPoGodiniContainer}>
          {
            predmetiIVGodina.length ?
            (
              predmetiIVGodina.map((predmet, index) => {
                return (
                  <Predmet
                    key = {index}
                    data = {predmet}
                    />
                )
              })
            )
            : null
          }
        </div>
        { (error) ? <div>Дошло је до грешке. Покушајте поново.</div> : null }
      </div>
  );
}

export default DrustveniSmer;
