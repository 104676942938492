import React from 'react';
import ImageSlideShow from '../layout/ImageSlideShow';

// stil za sve sekcije je ovde
import styling from './DramaClub.module.css';

const SportSection = () => {

  const images = [
    {
      title: "",
      src: "/files/sportskaSekcija/sport1.jpg",
      alt: "",
      text: ""
    },
    {
      title: "",
      src: "/files/sportskaSekcija/sport2.jpg",
      alt: "",
      text: ""
    },
    {
      title: "",
      src: "/files/sportskaSekcija/sport3.jpg",
      alt: "",
      text: ""
    },
    {
      title: "",
      src: "/files/sportskaSekcija/sport4.jpg",
      alt: "",
      text: ""
    },
    {
      title: "",
      src: "/files/sportskaSekcija/sport5.jpg",
      alt: "",
      text: ""
    }
  ];

  return (
      <div>
        <div>
          <h3> Спортска секција</h3>

          <img src="/files/sportskaSekcija/sport3.jpg" className={styling.coverImage} alt="Спортска секција" />

          <p>У нашој гимназији се одувек водило рачуна о настави физичког васпитања, без обзира што услови у сали и дворишту нису адекватни али и професори и ученици се труде да се настава и секције што боље реализују. Поред редовне наставе, све време раде секције, пре свега кошаркашка, одвојкашка, рукометна, фудбалска, атлетска, овим такмичењима је обухваћен велики број ученика. Наши ученици учествују и на такмичењима у пливању, стоном-тенису, стрељаштву,каратеу, џудоу. У свим овим спортовима постижу се изузетни резултати на општинском, окружном, међуокружном и наравно републичком нивоу.</p>
          <p>На последњем одржаном СОШОВ-у ( Спортска олимпијада школске омладине Војводине) 2018.учествовало је преко 70 наших ученика, а најбоље пласиране су мушка кошаркашка и атлетска екипа које су  освојиле 2. место.На републичком такмичењу у атлетица 2018. мушка екипа је освојила 2.место, а на истом такмичењу смо освојили и златну и сребрну медаљу у појединачниј мушкој и женској конкуренцији.Успеси наших пливача су изузетни на републичим такмичењима имајући у виду чињеницу да су наше ученице сада успешне европске и светске пливачице.</p>
          <p>Наша школа је 2021. учествовала на ISF World Cool Games у Украјини, представљала Србију и заузела 5. место.</p>
          <p>Гимназија је 2017.године добила и награду за најбољу средњу школу, на основу постигнутих спортских резултата, као и награду за најбољег професора физичког васпитања.</p>
        </div>

        <div>
          <ImageSlideShow images={images} />
        </div>

      </div>
    );

}

export default SportSection;
