import React from 'react';

class InternationalCooperation extends React.Component {
  render() {
    return (
      <div>
        <h3>Међународна сарадња</h3>
      </div>
    )
  }
}

export default InternationalCooperation;
