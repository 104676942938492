import React from 'react';
import styling from './ImportantLinks.module.css';

const ImportantLinks = () => {
  return (
    <div className={styling.sidePanelLinks}>
      <a href="https://www.mojaskola.gov.rs/" rel="noopener noreferrer nofollow" target="_blank">Моја школа</a>
      <a href="https://petlja.org/" rel="noopener noreferrer nofollow" target="_blank">Петља</a>
      <a href="http://www.mpn.gov.rs/" rel="noopener noreferrer nofollow" target="_blank">Министарство просвете науке и технолошког развоја</a>
      <a href="http://www.puma.vojvodina.gov.rs/" rel="noopener noreferrer nofollow" target="_blank">Покрајински секретаријат за образовање</a>
    </div>
  );
}

export default ImportantLinks;

/*
<a href="http://sbn.mpn.gov.rs/" target="_blank" rel="noreferrer">Школа без насиља</a>
<a href="http://zelenaucionica.com/" target="_blank" rel="noreferrer">Зелена учионица</a>
*/
