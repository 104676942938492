import React from 'react';
//import { Link } from 'react-router-dom';
import {Container, Row} from 'react-bootstrap';
import AdministrationPosition from '../layout/AdministrationPosition';

import styling from './Contact.module.css';

function Contact() {
  return (
    <div className={styling.contactContent}>
      <Container className={styling.padding}>
        <Row>
          <p>Игњата Барајевца 5</p>
          <p>26101 Панчево</p>
          <p>Теl/fax +381 13/ 301 120, 344 483</p>
          <p><a href="http://www.gimnazijaurospredic.edu.rs" className={styling.link}>www.gimnazijaurospredic.edu.rs</a></p>
        </Row>
        <Row>
          <div id="googlemap">
            <iframe width="100%" height="400" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2827.5912034395737!2d20.635404765902003!3d44.87061637909836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7e952856d127%3A0xb83763b1e7ede9b4!2z0JPQuNC80L3QsNC30LjRmNCwIOKAntCj0YDQvtGIINCf0YDQtdC00LjRm-KAnCwg0JjQs9Ga0LDRgtCwINCR0LDRgNCw0ZjQtdCy0YbQsCA1LCDQn9Cw0L3Rh9C10LLQviAzMDA4MDM!5e0!3m2!1ssr!2srs!4v1636826789818!5m2!1ssr!2srs" title="myMap" loading="lazy"></iframe>
          </div>
        </Row>
        <Row>
          <AdministrationPosition
                    key={1}
                    id={1}
                    name={"Зорица"}
                    lastName={"Помар"}
                    position={"директор школе"}
                    email={"direktor@gimnazijaurospredic.edu.rs"}
                    phone={"0604344577"}
                    src={"/avatars/female.png"}
                  />
          <AdministrationPosition
                    key={2}
                    id={2}
                    name={"Милутин"}
                    lastName={"Којић"}
                    position={"помоћник директора"}
                    email={"pomocnik@gimnazijaurospredic.edu.rs"}
                    phone={"0604344521"}
                    src={"/avatars/male.png"}
                  />
        </Row>

        <Row>
          <AdministrationPosition
                  key={3}
                  id={3}
                  name={"Игор"}
                  lastName={"Гаврић"}
                  position={"секретар"}
                  email={"gimnazijaurospredicpancevo@gmail.com"}
                  phone={"013/301120"}
                  src={"/avatars/male.png"}
                />
          <AdministrationPosition
                  key={10}
                  id={10}
                  name={"Милана"}
                  lastName={"Николић"}
                  position={"административни радник"}
                  email={"referentmilana@gimnazijaurospredic.edu.rs"}
                  phone={"013/301120"}
                  src={"/avatars/female.png"}
                />
          <AdministrationPosition
                  key={4}
                  id={4}
                  name={"Душка"}
                  lastName={"Ђаковић"}
                  position={"административни радник"}
                  email={"duskadjakovic@gimnazijaurospredic.edu.rs"}
                  phone={"013/301120"}
                  src={"/avatars/female.png"}
                />
          <AdministrationPosition
                    key={5}
                    id={5}
                    name={"Катерина"}
                    lastName={"Калапиш"}
                    position={"административни радник"}
                    email={"katerina.kalapis@gimnazijaurospredic.edu.rs"}
                    phone={"013/301120"}
                    src={"/avatars/female.png"}
                />
        </Row>

        <Row>
          <AdministrationPosition
                  key={6}
                  id={6}
                  name={"Ана"}
                  lastName={"Владић"}
                  position={"школски педагог"}
                  email={"ppsluzba.gimnazijapancevo@gmail.com"}
                  phone={"013/301120"}
                  src={"/avatars/female.png"}
                />
          <AdministrationPosition
                  key={7}
                  id={7}
                  name={"Јанковић"}
                  lastName={"Душанка"}
                  position={"школски психолог"}
                  email={"ppsluzba.gimnazijapancevo@gmail.com"}
                  phone={"013/301120"}
                  src={"/avatars/female.png"}
                />
          <AdministrationPosition
                    key={8}
                    id={8}
                    name={"Силвија"}
                    lastName={"Паталов Трајковић"}
                    position={"школски педагог, библиотекар"}
                    email={"ppsluzba.gimnazijapancevo@gmail.com"}
                    phone={"013/301120"}
                    src={"/avatars/female.png"}
                />
          <AdministrationPosition
                    key={9}
                    id={9}
                    name={"Александар"}
                    lastName={"Ратковић"}
                    position={"библиотекар"}
                    email={""}
                    phone={"013/301120"}
                    src={"/avatars/male.png"}
                />
        </Row>
      </Container>
    </div>
  );
}

export default Contact;

/*
<Col xs={12} md={3} lg={4}>
  <Card border="warning">
    <Card.Header className={styling.newsHeader}>Заменик директора</Card.Header>
    <Card.Body>
      <Card.Title> </Card.Title>
      <Card.Text>
      <p>Игњата Барајевца 5</p>
      <p>26101 Панчево</p>
      <p>Теl/fax +381 13/ 301 120, 344 483</p>
      <p>www.gimnazijaurospredic.edu.rs</p>
      </Card.Text>
    </Card.Body>
  </Card>
</Col>
<Col xs={12} md={3} lg={4}>
  <Card border="warning">
    <Card.Header className={styling.newsHeader}>Остала администрација</Card.Header>
    <Card.Body>
      <Card.Title> </Card.Title>
      <Card.Text>
      <p>Игњата Барајевца 5</p>
      <p>26101 Панчево</p>
      <p>Теl/fax +381 13/ 301 120, 344 483</p>
      <p>www.gimnazijaurospredic.edu.rs</p>
      </Card.Text>
    </Card.Body>
  </Card>
</Col>

*/
