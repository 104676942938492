import React from 'react';

const RockMarathon = () => {

  return (
      <div>
        <h3>Rock маратон</h3>
      </div>

    );

}
export default RockMarathon;
