import React from 'react';
import axios from 'axios';
import Profesor from '../layout/Profesor';
import styling from './Professors.module.css';

class Professors extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      err: ""
    }
  }

  // izvrsi se samo jednom kad se po prvi put komponenta mount-uje!!!
  componentDidMount(){
    axios.get('http://62.240.9.17:3003/selectProfesori')
    .then(response => {
      /*console.log(response);*/
      this.setState({data: Array.from(response.data)});
      window.scrollTo(0, 0);
    })
    .catch(error => {
      console.log(error);
      this.setState({err: "Дошло је до грешке. Покушајте поново."});
    });
  }

  render() {
    // destructure data...
    const {data, err} = this.state;
    //render data
    return (
      <div>
        <h3 className={styling.center}>Професори</h3>
        {
          data.length ?
          data.map(profesor => {
            return (<Profesor
                      key={profesor.id}
                      id={profesor.id}
                      name={profesor.ime}
                      lastName={profesor.prezime}
                      cv={profesor.cv}
                      otvorenaVrata={profesor.otvorena_vrata}
                      email={profesor.email}
                      alt={profesor.alt}
                      src={profesor.src}
                    />)
          }) :
          null
        }

        {
          err ? <div>{err}</div> : null
        }

      </div>
    );
  }
}

export default Professors;
