import React, {useEffect} from 'react';
import News from '../layout/news';
//import ImageSlideShow from '../layout/ImageSlideShow';

const  HomePage = () => {

  /*const images =  [
    {
      title: "Текст",
      src: "/skola2.jpg",
      alt: "школа.jpg",
      text: "Неки текст 1"
    },
    {
      title: "Текст",
      src: "/skola2.jpg",
      alt: "школа.jpg",
      text: "Неки текст 2"
    },
    {
      title: "Текст",
      src: "/skola2.jpg",
      alt: "школа.jpg",
      text: "Неки текст 3"
    },
    {
      title: "Текст",
      src: "/skola2.jpg",
      alt: "школа.jpg",
      text: "Неки текст 4"
    }
];*/

  useEffect(() => {window.scrollTo(0,0);}, []);

  return (
    <div>

      <div>
        <News />
      </div>
    </div>);
}

export default HomePage;

/*
<div>
  <ImageSlideShow
    images = {images}
  />
</div>
*/
