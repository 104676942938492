import React from 'react';
import { Link } from 'react-router-dom';
import styling from './JavneNabavke.module.css';

class JavneNabavke extends React.Component {
  render() {
    return (
      <div className={styling.javneNabavke}>
				<h3 className={styling.title}>2024</h3>
				<Link to="/files/javneNabavke/2024/gimnazijaFinansijskiPlan2024.pdf" target="_blank"><img className={styling.icon} src="pdfIcon.jpg" alt="pdf"/> Финансијски план за 2024. годину</Link>
			 	<br/>
        <br/>
			
				<h3 className={styling.title}>2023</h3>
				<Link to="/files/javneNabavke/2023/finansijskiIzvestajGimnazija2023.pdf" target="_blank"><img className={styling.icon} src="pdfIcon.jpg" alt="pdf"/> Финансијски извештај за 2023. годину</Link>
				<br/>
				<Link to="/files/javneNabavke/2023/gimnazijaFinansijskiPlan2023.pdf" target="_blank"><img className={styling.icon} src="pdfIcon.jpg" alt="pdf"/> Финансијски план за 2023. годину</Link>
			 	<br/>
        <br/>
				
				
				<h3 className={styling.title}>2022</h3>
				<Link to="/files/javneNabavke/2022/gimnazijaFinansijskiIzvestaj2022.pdf" target="_blank"><img className={styling.icon} src="pdfIcon.jpg" alt="pdf"/> Финансијски извештај за 2022. годину</Link>
			 	<br/>
				<Link to="/files/javneNabavke/2022/gimnazijaFinPlan2022.pdf" target="_blank"><img className={styling.icon} src="pdfIcon.jpg" alt="pdf"/> Финансијски план за 2022. годину</Link>
			 	<br/>
        <br/>
				
				<h3 className={styling.title}>2021</h3>
				<Link to="/files/javneNabavke/2021/finansijskiIzvestajGimnazija2021.pdf" target="_blank"><img className={styling.icon} src="pdfIcon.jpg" alt="pdf"/> Финансијски извештај за 2021. годину</Link>
				<br/>
				<Link to="/files/javneNabavke/2021/GIMNAZIJAfinplankonačni 2021.pdf" target="_blank"><img className={styling.icon} src="pdfIcon.jpg" alt="pdf"/> Финансијски план за 2021. годину</Link>
			 	<br/>
        <br/>
			 	<h3 className={styling.title}>2020</h3>
				<Link to="/files/javneNabavke/2020/Finansijskiizvestajgimnazija2020.pdf" target="_blank"><img className={styling.icon} src="pdfIcon.jpg" alt="pdf"/> Финансијски извештај за 2020. годину</Link>
				<br/>
				<Link to="/files/javneNabavke/2020/javneNabavke2020.jpg"><img className={styling.icon} src="pdfIcon.jpg" alt="pdf"/> План јавних набавки за 2020. годину</Link>
			 	<br/>
				<Link to="/files/javneNabavke/2020/finansijskiPlan2020.pdf" target="_blank"><img className={styling.icon} src="pdfIcon.jpg" alt="pdf"/> Финансијски план за 2020. годину</Link>
				<br/>
        <br/>
			 	<h3 className={styling.title}>2019</h3>
				<Link to="/files/javneNabavke/2019/konkursnaDokumentacijaEks2019.doc" id="me2019"><img className={styling.icon} src="pdfIcon.jpg" alt="pdf"/> Конкурсна документација - матурска екскурзија 2019/20</Link>
			 	<br/>
				<Link to="/files/javneNabavke/2019/pozivEkskurzija2019.doc"><img className={styling.icon} src="pdfIcon.jpg" alt="pdf"/> Позив за поднешење понуда - матурска екскурзија 2019/20</Link>
			 	<br/>
			 	<Link to="/files/javneNabavke/2019/2019planJavnihNabavki.pdf"><img className={styling.icon} src="pdfIcon.jpg" alt="pdf"/> План јавних набавки 2019</Link>
			 	<br/>
			 	<Link to="/files/javneNabavke/2019/2019konkursnaDokumentacijaElEnergija.pdf"><img className={styling.icon} src="pdfIcon.jpg" alt="pdf"/> Конкурсна документација електрична енергија 2019</Link>
			 	<br/>
			 	<Link to="/files/javneNabavke/2019/2019pozivElEnergija.pdf"><img className={styling.icon} src="pdfIcon.jpg" alt="pdf"/> Позив: електрична енергија 2019</Link>
			 	<br/>
				<Link to="/files/javneNabavke/2019/odlukaDodelaUgovoraElekEnergij2019.pdf"><img className={styling.icon} src="pdfIcon.jpg" alt="pdf"/> Одлука: електрична енергија 2019</Link>
				<br/>
				<Link to="/files/javneNabavke/2019/Dodelaugovora.jpg"><img className={styling.icon} src="pdfIcon.jpg" alt="pdf"/> Одлука о додели уговора - замена дела фасадне столарије</Link>
      </div>
    )
  }
}

export default JavneNabavke;
