import React from 'react';
import styling from './About.module.css';

function About() {
  return (
    <div>
      <h3>О гимназији „Урош Предић“</h3>
      <p>Гимназија „Урош Предић“ у Панчеву је школа са дугом традицијом. У 2018. години обележавамо 155-годишњицу њеног постојања.</p>
      <p>Идеју о оснивању српске гимназије у Панчеву покренуло је српско грађанство тридесетих година 19. века. Двојица Панчеваца завештала су део имовине у културне сврхе. Великодушним гестом берберина и житарског трговца Томе Сандуловића 1835. године почиње стварање фонда за оснивање гимназије. Годину дана касније житарски трговац Игњат Барајевац завештао је имање које се састојало од велике зграде, гостионице „Код звезде“, за оснивање ђачког интерната. Али, и поред ових легата, средстава нису била довољна, а власти нису биле вољне да финансијски помогну, те се оснивање гимназије одлаже.</p>
      <img src="/skola1.jpg" className={styling.textimg} alt="Гимназија 19. век" />

      <p>Наредбом Дворског ратног савета у Бечу 1851. године отворена је Царско-краљевска нижа реалка, да би се 1863. године претворила у
        Царско-краљевску вишу реалку. Ову годину узимамо за годину оснивања Гимназије. У то време ђак ове немачке реалке током две школске
        године био је Лаза Костић, највећи поета српског романтизма.</p>
      <p>Школа је више пута мењала име, а од 1958. године до данас носи име великог српског сликара <i>Уроша Предића</i>. Мењала се и
      зграда школе: прво је била смештена у кући Карла Вајферта (угао Улица Ђуре Јакшића и Николе Ђурковића), потом у данашњој Његошевој улици бр.8, а онда на месту војног магацина. Изградња нове, тј. данашње зграде, завршена је 1888.г. Зграда је једноспратна у класицистичком стилу, има главну фасаду и два истурена бочна крила. У дворишту је фискултурна сала.   Свечано отварање почело је мисом у суседној римокатоличкој цркви миноритског манастира.
      На левој страни степеништа зазидана је гранитна плоча са натписом „Завршни камен темељац
      19. новембра 1888. године“ иза које је у шупљини смештена споменица.   Била је у то време једна од најбоље опремљених школа у Војводини и најугледнијих у Аустро-Угарској, јер су у њој предавали бечки професори. На Париској изложби 1867. године њени ученици и професори добили су похвалу и награду за своје радове.
      Основни задатак Гимназије био је да пружи опште образовање и добро познавање класичних језика и историје књижевности, како би
      припремила ђаке за студије на универзитету. Све до слома Аустро-Угарске монархије, била је то школа за имућније слојеве друштва.
       Ученик панчевачке реалке био је <i>Михаило Пупин (1870-1872)</i>. Морао је да напусти школу, да не би био избачен, због учешћа
      на бакљади у част доласка Светозара Милетића у Панчево. Велики сликар <i>Урош Предић</i> завршио је свих седам разреда Гимназије,
      одакле је отишао у Беч на Уметничку академију 1876. године.</p>

      <p>Tоком Првог светског рата Гимназија је на кратко прекинула рад и била претворена у војну болницу. По завршетку рата гимназијалци су суочени са тешкоћама: недостатак наставника, уџбеника, огрева...
      Школа се даље развијала и осавремењивала. Велика пажња посвећивала се васпитању и дисциплини: забрана одласка у биоскоп ђацима са три слабе оцене; кецеље бар 10 цм испод колена; затвор као казна за одређене преступе.
      Гимназија је одувек имала посебну културну улогу у Панчеву. Одржавана су бројна предавања за ученике и грађане из разних научних области. Школа је имала сјајан хор који је освајао награде, за време диригента Јована Бандура (1927-1931). Успешан је био и гудачки оркестар, као и фолклорна група. Нису изостали ни спортски успеси.
      Наш познати књижевник Милош Црњански био је наставник Гимназије током 1921. и 1922. године. Држао је часове гимнастике, и часове српског језика, историје и земљописа.
      У Другом светском рату окупационе власти изместиле су Гимназију из ове зграде. Настава се одвијала у разним просторијама на територији града. Радило се чак у четири смене. Развлачена је школска имовина, учила, библиотека је делом уништена. Неки професори су пензионисани, отпуштани, а неки професори и ученици су изгубили животе.
      По завршетку рата мењали су се планови и програми, уводили нови предмети, унапређивала настава отварањем кабинета, лабораторија,
      обогаћивањем књижног фонда, организовањем екскурзија, стручним усавршавањем професора, оснивањем секција и научних група...</p>

      <img src="/skola2.jpg" className={styling.textimg} alt="Гимназија данас" />

      <p>Од 1958. година школа носи име Гимназија „Урош Предић“, које се мења у Центар за образовање стручних радника у друштвеним делатностима 1977. године. Школске 1990-1991. године враћено је име Гимназија „Урош Предић“ са два смера, друштвено-језичким и природно-математичким.
      Гимназија је данас поносни чувар традиције образовања у нашој земљи. Многи наши ученици били су, или данас јесу угледни и афирмисани академски грађани, уметници, лекари, професори, спортисти. Међу славним именима већ су поменути електроинжењер, физичар и проналазач <i>Михаило Пупин</i>, сликар <i>Урош Предић</i>, књижевник <i>Милош Црњански</i>, прота <i>Васа Живковић</i>, композитор <i>Димитрије Мита Топаловић</i>. Ученици су били и сликар <i>Стојан Трумић</i>, академик <i>Димитрије Стефановић</i>, песник <i>Душан Вукајловић</i>, архитекта <i>Небојша Маројевић</i>, математичар <i>Стево Тодорчевић</i>, одбојкаш <i>Зоран Гајић</i>, рукометаш <i>Жикица Милосављеви</i>, глумац <i>Небојша Глоговац</i>, пливачица <i>Нађа Хигл</i>...
      И данас, Гимназија је препознатљива  по свом духу. И даље постоје бројне секције и клубови које ученици воле. Наши гимназијалци редовно освајају награде на свим нивоима такмичења. Драмска секција је прерасла у драмски клуб. Ученици су чланови лингвистичке, литерарне, ликовне секције, бројних спортских секција. Гимназијски бенд ученика и професора, као и сјајан хор, и маскенбали, гарант су доброг расположења. Ученици имају своје часописе „Хаос“ и „Декадент“. Школа има одличну сарадњу
      са Регионалним центром за таленте „Михајло Пупин“ и Истраживачком станицом у Петници. Ученици су веома друштвено ангажовани и
      укључени у бројне акције локалне заједнице.<br/>Школa је реализатор Pach- пројекта и пројекта Connecting classrooms.</p>
      <br/>
      <h3>Знаменити гимназијалци</h3>

      <div className={styling.imgcontainer}>
        <img src="/znamenitiGimnazijalci/predic.jpg" alt="Урош предић" />
        <div className={styling.imgtextcentered}>Урош предић</div>
      </div>

      <div className={styling.imgcontainer}>
        <img src="/znamenitiGimnazijalci/vasa.jpeg" alt="Васа Живковић" />
        <div className={styling.imgtextcentered}>Васа Живковић</div>
      </div>

      <div className={styling.imgcontainer}>
        <img src="/znamenitiGimnazijalci/pupin.jpg" alt="Михаило Пупин" />
        <div className={styling.imgtextcentered}>Михаило Пупин</div>
      </div>

      <div className={styling.imgcontainer}>
        <img src="/znamenitiGimnazijalci/crnjanski.jpg" alt="Милош Црњански" />
        <div className={styling.imgtextcentered}>Милош Црњански</div>
      </div>

      <div className={styling.imgcontainer}>
        <img src="/znamenitiGimnazijalci/glogovac.jpg" alt="Небојша Глоговац" />
        <div className={styling.imgtextcentered}>Небојша Глоговац</div>
      </div>
    </div>
  );
}

export default About;
