import React from 'react';
import { Table } from 'react-bootstrap';
import styling from './ClassScheduleTime.module.css';

class ClassScheduleTime extends React.Component {

  /*constructor(props) {
    super(props);
  }*/

  render(){
    return (
      <div className={styling.ClassScheduleTime}>
        <h3> Распоред звоњења </h3>
				<Table bordered hover>
          <thead>
            <tr><th className={styling.center} colSpan="3">Сатница звоњења: 45 мин.</th></tr>
            <tr><th>Час</th><th>Преподне</th><th>Послеподне</th></tr>
          </thead>
          <tbody>
            <tr>
  						<td>1.</td><td>7:30-8:15</td><td>13:40-14:25</td>
  					</tr>
  					<tr>
  						<td>2.</td><td>8:20-9:05</td><td>14:30-15:15</td>
  					</tr>
  					<tr>
  						<td>3.</td><td>9:10-9:55</td><td>15:20-16:05</td>
  					</tr>
  					<tr>
  						<td>4.</td><td>10:15-11:00</td><td>16:25-17:10</td>
  					</tr>
  					<tr>
  						<td>5.</td><td>11:05-11:50</td><td>17:15-18:00</td>
  					</tr>
  					<tr>
  						<td>6.</td><td>11:55-12:40</td><td>18:05-18:50</td>
  					</tr>
  					<tr>
  						<td>7.</td><td>12:45-13:30</td><td>18:55-19:40</td>
  					</tr>
          </tbody>
				</Table>
        <br/>

        <Table bordered hover>
          <thead>
            <tr><th className={styling.center} colSpan="3">Сатница звоњења: 30 мин.</th></tr>
            <tr><th>Час</th><th>Преподне</th><th>Послеподне</th></tr>
          </thead>
          <tbody>
            <tr>
							<td>1.</td><td>7:30-8:00</td><td>11:50-12:20</td>
						</tr>
						<tr>
							<td>2.</td><td>8:05-8:35</td><td>12:25-12:55</td>
						</tr>
						<tr>
							<td>3.</td><td>8:40-9:10</td><td>13:00-13:30</td>
						</tr>
						<tr>
							<td>4.</td><td>9:25-9:55</td><td>13:45-14:15</td>
						</tr>
						<tr>
							<td>5.</td><td>10:00-10:30</td><td>14:20-14:50</td>
						</tr>
						<tr>
							<td>6.</td><td>10:35-11:05</td><td>14:55-15:25</td>
						</tr>
						<tr>
							<td>7.</td><td>11:10-11:40</td><td>15:30-16:00</td>
						</tr>
          </tbody>
				</Table>

      </div>
    )
  }
}

export default ClassScheduleTime;
