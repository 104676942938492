import React from 'react';
import ImageSlideShow from '../layout/ImageSlideShow';
import styling from './DramaClub.module.css';

const GimnazijskiIdol = () => {

  const images = [
    {
      title: "Милан Јовановић и Момчило Јовановић гитаристи у бенду",
      src: "/files/gimnazijskiIdol/MilanJovanoviciMomciloJovanovicgitaristiubendu.jpg",
      alt: "Милан Јовановић и Момчило Јовановић гитаристи у бенду",
      text: ""
    },
    {
      title: "Победница по гласовима жирија Сара Илић",
      src: "/files/gimnazijskiIdol/PobednicapoglasovimazirijaSaraIlic.jpg",
      alt: "Победница по гласовима жирија Сара Илић",
      text: ""
    },
    {
      title: "Победници публике Луди летњи плес",
      src: "/files/gimnazijskiIdol/PobednicipublikeLudiletnjiples.jpg",
      alt: "Победници публике Луди летњи плес",
      text: ""
    },
    {
      title: "Проглашење победника",
      src: "/files/gimnazijskiIdol/Proglasenjepobednika.jpg",
      alt: "Проглашење победника",
      text: ""
    },
    {
      title: "Теодора Гњидић клавијатуристкиња бенда",
      src: "/files/gimnazijskiIdol/TeodoraGnjidicklavijaturistkinjabenda.jpg",
      alt: "Теодора Гњидић клавијатуристкиња бенда",
      text: ""
    },
    {
      title: "Учеснице Тамара Божић и Марија Гајић",
      src: "/files/gimnazijskiIdol/UcesniceTamaraBoziciMarijaGajic.jpg",
      alt: "Учеснице Тамара Божић и Марија Гајић",
      text: ""
    },
    {
      title: "Водитељке Идола",
      src: "/files/gimnazijskiIdol/VoditeljkeIdola.jpg",
      alt: "Водитељке Идола",
      text: ""
    }
  ];

  return (
      <div>
        <h3>Гимназијски идол</h3>
        <img src="/files/gimnazijskiIdol/PobednicipublikeLudiletnjiples.jpg" className={styling.coverImage} alt="Победници публике - Луди летњи плес" />
        <p>Гимназијски идол је такмичење ученика гимназије у певању популарних поп и рок хитова. Тадашњи Музички клуб Гимназије „Урош Предић“ предвођен професором Дабић Миленком је на идеју за ово такмичење дошао по угледу на тада популарно такмичење Поп Идол. Отуда и назив ове манифестације.</p>
        <p>Први пут је одржано пролећа 2007. године у кафеу Купе. Исте године, велико финале је одржано на отвореном, на платоу испред Музичке школе пред око хиљаду људи. Прва победница била је тадашња ученица трећег разреда Рада Сарић. Такмичење је одржано још 2009. и 2010. године, након чега је због све мање заинтересованих престало да се одржава.</p>
        <p>Након готово десет година паузе, Гимназијски идол је поново покренут у јесен 2018. године, на иницијативу директорке Зорице Помар. Главни руководилац је поново био професор Дабић Миленко, уз помоћ професора Којић Милутина.</p>
        <p>Ученици су показали велико интересовање, те је било чак 15 такмичарских песама (рекордан број до тада). Финале је одржано 5. децембра 2018. године у дворани Аполо и било је замишљено као део обележавања дана школе. Финалу је присуствовало око 500 посетилаца.</p>
        <p>Победника је одлучивао стручни жири (који су чинили бивши гимназијалци и музичари). Прву награду освојила је ученица првог разреда Сара Илић, другу награду је освојила матуранткиња Јустина Михајловић, док је треће место по одлуци жирија припало ученици трећег разреда Милици Тимић.Што се тиче гласова публике (гласала је публика у дворани користећи купоне са улазница) највише гласова је освојила такође Сара Илић.
Део прихода од продаје улазница искоришћен је за куповину нових бубњева који су недостајали школи.
Водитељи програма били су бивши гимназијалци Марија Зенг и Михајло Петковић.</p>
        <p>Гимназијски идол 2019. године оборио је претходни рекорд по броју такмичарских песама. Било их је чак 17. Одржан је поново на истом месту, у дворани Аполо, 10. децембра 2019. године. Као и претходне године, за организацију су задужени професори Дабић Миленко (који је руководио пробама) и Којић Милутин (који се бавио организационим и продукцијским пословима). Стручни жири је поново прву награду додлеио Сари Илић, док је по мишљењу публике најбоља била група коју су чинили Милица Самарџија, Јована Брујић, Дуња Станојковић и Александар Станишковић.
Водитељи програма били су: Ања Петровић, Ања Јакимовски, Немања Вујић и Исидора Дадић (која је и написала сценарио за водитеље).
У ревијалном делу вечери, наступила је панчевачка група Рампа</p>
        <p>
Због пандемије корона вируса, Гимназијски идол није одржан 2020. и 2021. године, али је у плану, ако епидемиолошки услови то буду дозволили, да на пролеће 2022. године буде настављена ова манифестација.</p>

        <div>
            <ImageSlideShow images={images} />
        </div>


      </div>
    );

}

export default GimnazijskiIdol;
