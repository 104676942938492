import React from 'react';

class Parlament extends React.Component {
  render() {
    return (
      <div>
        <h3>Ученички парламент</h3>
        <p>ИЗВОД ИЗ СТАТУТА ШКОЛЕ</p>
        <p>5.1.20. УЧЕНИЧКИ ПАРЛАМЕНТ</p>
        <p>Члан 266.</p>
        <p>Гимназији се организује ученички парламент који чине по два представника сваког
одељења у Гимназији. Чланове парламента бирају ученици на почетку сваке школске године, на
првом часу одељењске заједнице.</p>
        <p>Чланови парламента бирају председника, два представника ученика који учествују у раду
Школског одбора без права одлучивања и представнике у стручним органима Гимназије.</p>
        <p>Члан 267.</p>
        <p>Рад Ученичког парламента уређен је Пословником о раду ученичког парламента</p>
        <p>Члан 268.</p>
        <p>Надлежност Ученичког парламента Гимназије је:</p>
        <ol>
          <li>давања мишљења и предлога стручним органима, Школском одбору, Савету родитеља и
директору о: правилима понашања у Гимназији, мерама безбедности ученика, годишњем плану
рада, школском развојном плану, школском програму, начину уређивања школског простора,
избору уџбеника, слободним активностима, учешћу на спортским и другим такмичењима и
организацији свих манифестацијама ученика у Гимназији и ван ње и другим питањима од значаја
за њихово образовање</li>
          <li>разматрање односа и сарадње ученика и наставника или стручног сарадника и атмосфере у
Гимназији</li>
<li>обавештавање ученика о питањима од посебног значаја за њихово школовање и о
активностима ученичког парламента</li>
          <li>активно учешћа у процесу планирања развоја Гимназије и у самовредновању Гимназије;</li>
          <li>предлагања чланова Стручног актива за развојно планирање и Тима за превенцију вршњачког
насиља из реда ученика</li>
        </ol>
        <p>Члан 269.</p>
        <p>Програм рада Ученичког парламента је саставни део годишњег плана рада Гимназије.</p>
        <p>Члан 270.</p>
        <p>Ученички парламенти могу да се удруже у Заједницу ученичких парламената.</p>
        <p>Ученички парламент може да сарађује са удружењима и организацијама које се баве
заштитом и унапређењем права ученика.</p>
        <p>Ученички парламент, уз сагласност Школског одбора, може основати Ученички клуб.
План рада Ученичког клуба доноси Школски одбор на предлог Ученичког парламента, као
саставни део Школског програма.</p>
        <p>Начин рада Ученичког клуба одређује се посебним актом - Пословником.</p>
      </div>
    )
  }
}

export default Parlament;
