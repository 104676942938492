import React from 'react';

const Chor = () => {
  return (
    <div>
      <div>
        <h3>Хор Гимназије „Урош Предић“</h3>
        <p>Осврт на сам почетак рада у просвети, па све до сада, када сам се нашла пред задатком да на моменат „заокружим“ године рада у Гимназији и рада са хором, изнова ме подсећа колико уживам у ономе што радим. </p>
        <p>Направити „скицу“ и обликовати утиске кроз време које се невероватном  брзином мења, у први мах није нимало лако, али снага уметности, чини се, успева да премости  тај  јаз и време на тренутак учини непролазним.</p>
        <p>Присећајући се студентских дана и певања у Цоллегиум мусицуму, успешно повлачим паралелу са оним што покушавам да пренесем и данас. Рад са ученичким хором чини да оно за чим каткад чезнем, постаје поново присутно.</p>
        <p>У знак обележавања 140 година постојања Гимназије, 2003. године оформљен је хор, који тада броји око 70 чланова и ученички оркестар.</p>
        <p>Сумирани утисци након успешног представљања били су одскочна даска и почетак присуства нове енергије. Инспирисан таквим тренуцима и атмосфером, мој рад са ђацима и љубав према музици добијају дубљи смисао, уносе освежење у свакодневницу и дају осећај испуњености. Низом наступа, испратили смо деценију за нама, а хор се обнављао и растао из године у годину, те смо јубиларних 150 година Гимназије, 2013. прославили наступом хора, који тада броји чак 132 члана. Хитовима који „вечно опстају“, интерпретацијама класичног и модерног, „бојимо“ сцену, путујемо кроз време и разговарамо мелодијама. </p>
        <p>Хор наступа сваке године за Дан Гимназије, као и на разним другим манифестацијама и прославама, како у школи тако и у граду: на Светосавској академији, за Дан европе, Дан планете Земље, у Евангелистичкој цркви у време божићних празника, у оквиру Пијанистичког маратона у музичкој школи „Јован Бандур“ у организацији пијанисте и професора Николе Рацкова, на свечаности „Гимназији за јубилеј поштанска марка“, којој су поред многобројних званица присуствовали и г-дин Димитрије Стефановић, музиколог и члан САНУ, као и бивши селектор одбојкашке репрезентације Зоран Гајић. </p>
        <p>На позив градоначеника Панчева, хор је наступао и на прослави Дана града Панчева 8. новембра 2014. године, а следеће године и на свечаности поводом обележавања 200 година занатства у Панчеву.</p>
        <p>Наступао је са бендовима из Машинске школе на хуманитарној приредби ЈАПАНЧЕВО - помоћ Јапану,као и за представнике амбасада Немачке и  Шведске и који су посетили нашу школу. </p>
        <p>Певање и дружење у хору пружају осећај  заједништва, осећај да сте део целине, па синтезом музике и визуелне уметности  чини се да одајемо утисак савршеног споја. Давати подстрек у развијању талента ових младих људи сматрам изузетно важним, а њихово задовољство оним што заједно стварамо, пружа ми снагу и вољу да у овоме истрајем што дуже. </p>
        <p>Комуникација са ђацима кроз музику и осећања која она буди у њима, сасвим су довољна подршка да наставим да водим и одржавам овај  јединствени склад. Склад, у којем заборављамо на међусобне разлике, негујемо традицију и културу, како нашу тако и других народа јер музика не познаје границе, нити икакве језичке баријере, једино познаје и усваја универзални  језик, а то је љубав. </p>
        <p>Панчево, јануар 2022. професорка музичке културе Валентина Шимшић</p>
      </div>
      <div>
        <h3>Хор</h3>
        <p>Средином септембра 2007. моје новостечене другарице и ја стидљиво пролазимо по десети пут у току дана поред огласне табле у приземљу школе и очијукамо са натписом: Аудиција за нове чланове хора - придружите нам се!</p>
        <p>О успеху хора професорке Валетине Шимшић чуле смо и много пре него што смо и саме постале гимназијалке. Сви су знали и ценили хор наше школе. Не само наши ђаци и наши професори. Хор је наступао свуда по граду. Имао је одличан оркестар. Дивне уигране гласове и већ омиљене међу публиком солисте. Био је једна од упоришних тачки школе, тамо где је већина ђака маштала да буде током многих не тако распеваних часова. За нас, испрепадане новим окружењем петнаестогодишње гимназијалке био је то сувише велики изазов. Удружене својим страхом од непознатог, до краја дана, једногласно одлучујемо да ипак нећемо отићи на аудицију. Можда следеће године, ове ипак не.</p>
        <p>Срећом, постојао је неко ко је и те јесени веровао у шачицу гимназијалки. Наша професорка Валентина Шимшић. Дошетала је до наше учионице на спрату, провирила и довикнула нам уз благонаклони осмејак : „Девојке, видимо се на аудицији...“. Шта смо могле друго него да гурнемо све нереалне страхове и злобне гласиће у својим главама у страну и сложно потврдимо своје присуство на аудицији.</p>
        <p>Од тог дана па све до матуре хор је био моје уточиште. Колевка моје креативне енергије. </p>
        <p>Чињеница да нико од нас, на челу са проф. Валентином, на хор није гледао као на обавезу или још једну ваннаставну активност у низу, допринела је квалитету наше музикалне породице. Ми смо волели то што радимо. Волели смо да стварамо. Прерађујемо већ постојеће композиције, стварамо нове, откривамо таленте међу својим редовима, свесрдно их подржавамо и певамо, поврх свега - да певамо!</p>
        <p>Није било снажнијих емоција од оних које сам претварала у песму на пробама и наступима са хором. Све што ме је болело успевала сам да отпевам и преточим у стих . И никада нисам била сама. Ту је била моја велика дружина младих уметника окупљена под окриљем истог циља, циља уметности. Ослобађујући тренутак, када након вишемесечних проби, конструктивних критика, испевавања у недоглед, чујеш аплауз који се ори салом. И угледаш задовљно лице професорке која истим интезитетом верује у тебе и твоје пријатеље, младе гимназијалце, непрекидно, четири године.</p>
        <p>Мислим да смо само прву пробу успели да одржимо у једној од учионица, након тога морали смо се пребацити у фискултурну салу - постали смо још многобројнији. Наше добро расположење било је заразно. Све оно мање лепо или тужно што би се некоме од нас десило у току дана на проби је падало у заборав. Сваки члан био је подједнако важан. Из ове перспективе сасвим јасно видим колико ме је чланство у хору обликовало као личност.</p>
        <p>Можда је и добро што тада, док се све то дешавало и нисам била свесна колико је за мене насушно важно. Мислим да би ме блокирало. Плашила бих се његовог неминовног краја.</p>
        <p>Овако сам могла да уживам у његовом трајању. Ревносно сам увежбавала композиције на којима смо радили, радовала се сваком наступу. Понекад била несигурна у своје вокалне способности, али никада предуго. Увек би љубав према извођењу онога на чему толико предано радимо победила. Снажан осећај припадања заједници такође ми није дозвољавао да одустанем и изневерим своје пријатеље. Тако снажан колектив био је јачи од мојих несигурности.</p>
        <p>Важна лекција коју сам такође тада научила јесте улога ментора. Вођени примером професорке Шимшић научили смо да будемо одговорни. Ако бисмо се латили неког задатка нисмо од њега тек тако одустајали. Што су мање у нас веровали, ми смо били гласнији. Нисмо се бусали претходним успесима, никада нисмо живели од старе славе. Увек бисмо надоградили свако извођење, никада нису знали шта могу да очекују од нас. Осим да ћемо увек бити најбољи. И били смо. Зато смо ваљда и морали, на време, у тако танушним годинама, да научимо шта сваки успех носи са собом - понеки подозрив поглед, неретка негодовања и спотицања. </p>
        <p>Шта тада радиш? Певаш гласније него икада.</p>
        <p>Професорка и хор који је са љубављу и преданошћу градила дали су ми глас. Нисам сигурна да ћу икада успети да изразим у довољној мери своју захвалност за привилегију коју ми је указала.</p>
        <p>Паметнији од мене рекао је да ће лепота спасити свет. Слажем се и додајем да је мој спасила музика. Претпостављам да ће се моја захвалност за научено и проживљено током дана када сам певала у хору професорке Шимшић најбоље огледати у мојој одлучности да глас који сам пронашла у себи никада не утихне и увек буде аргументовано гласан.</p>
        <p>Баш онако како смо ми започињали сваки свој наступ ја завршавам свој запис - Исправите се, широки осмех и не заборавите да уживате. Живела музика!</p>
        <p>Заувек захвална, Милица Марковић генерација 2007/11.</p>
      </div>
    </div>
  );
}

export default Chor;
