// radnik administracija --> kontak strana
// ima isti stil kao Profesor kartica
import React from 'react';
import { Card } from 'react-bootstrap';
import styling from './Profesor.module.css';

class AdministrationPosition extends React.Component {

  render() {
    //let path = "/profesor/"+ this.props.id;
    return (
      <Card border="light" className={[styling.profesor, styling.learnMoreLink].join(" ")}>
        <Card.Img variant="top" src={this.props.src} alt={this.props.name + this.props.lastName} className={styling.avatar}/>
        <Card.Body>
          <Card.Title className={styling.center}>{this.props.name + " " + this.props.lastName}</Card.Title>

            <div className={styling.center}>
              {this.props.email}
            </div>
            <div className={styling.center}>
              {this.props.phone}
            </div>
            <div className={styling.center}>
              <strong>{this.props.position}</strong>
            </div>


        </Card.Body>
      </Card>

    )
  }
}

export default AdministrationPosition;
