//import { Link } from 'react-router-dom';
import React from 'react';
import styling from './mainNavigation.module.css';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';

function MainNavigation() {
  return (
    <header className={styling.header}>
      <Navbar sticky="top" expand="lg" bg="dark" variant="dark" >
        <Container className={styling.content}>
          <Navbar.Brand href="/"><img src="/logo.png" className={styling.logoimg} alt="ГимЛого" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/">Почетна</Nav.Link>
              <Nav.Link href="/onama">О школи</Nav.Link>
              <NavDropdown title="Настава" id="basic-nav-dropdown">
                <NavDropdown.Item href="/profesori">Професори</NavDropdown.Item>
                <NavDropdown.Item href="/otvorenaVrata">Отворена врата</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/kriterijumiOcenjivanja">Критеријуми оцењивања</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/udzbenici">Уџбеници</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/rasporedZvonjenja">Распоред звоњења</NavDropdown.Item>
                <NavDropdown.Item href="/rasporedCasova">Распоред часова</NavDropdown.Item>
                <NavDropdown.Item href="/rasporedPisanihProvera">Распоред писаних провера</NavDropdown.Item>
                <NavDropdown.Item href="/kalendar">Календар</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Смерови" id="basic-nav-dropdown">
                <NavDropdown.Item href="/prirodniSmer">Природни смер</NavDropdown.Item>
                <NavDropdown.Item href="/drustveniSmer">Друштвени смер</NavDropdown.Item>
                <NavDropdown.Item href="/itSmer">ИТ смер</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Ученици" id="basic-nav-dropdown">
                <NavDropdown.Item href="/maturanti">Матуранти</NavDropdown.Item>
                <NavDropdown.Item href="/takmicenja">Такмичења</NavDropdown.Item>
                <NavDropdown.Item href="/ucenickiParlament">Парламент</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Секције" id="basic-nav-dropdown">
                <NavDropdown.Item href="/idol">Гимназијски идол</NavDropdown.Item>
                <NavDropdown.Item href="/dramskiKlub">Драмски клуб</NavDropdown.Item>
                <NavDropdown.Item href="/lingvistickaSekcija">Лингвистичка секција</NavDropdown.Item>
                <NavDropdown.Item href="/hor">Хор</NavDropdown.Item>
                <NavDropdown.Item href="/sport">Спортска секција</NavDropdown.Item>
                <NavDropdown.Item href="/skolskiCasopisi">Школски часописи</NavDropdown.Item>
                <NavDropdown.Item href="/gimzeleni">Гимзелени</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Остало" id="basic-nav-dropdown">
                <NavDropdown.Item href="/pravilnici">Правилници</NavDropdown.Item>
                <NavDropdown.Item href="/javneNabavke">Јавне набавке</NavDropdown.Item>
              </NavDropdown>

              <Nav.Link href="/kontakt">Контакт</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default MainNavigation;

/*
  Остало:
  <NavDropdown.Item href="/medjunarodnaSaradnja">Међународна сарадња</NavDropdown.Item>
*/
