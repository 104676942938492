// prikaz pregleda (newsPreview) svake vesti

import React, { useEffect, useState} from 'react';
import axios from 'axios';
import NewsPreview from './newsPreview';
import styling from './news.module.css';

const News = () => {

  const [newsData, setNewsData] = useState([]);
  const [error, setError] = useState("");

  useEffect(()=> {
    axios.get("http://62.240.9.17:3003/selectNewNews")
    .then(response => {
      setNewsData(Array.from(response.data));
    })
    .catch(err => {
      setError(err);
    });

  }, []);


  return (
    <div className={styling.newsSection}>
      {
        newsData.length ?
        newsData.map(news => {
          return <NewsPreview
                    key={news.id}
                    id={news.id}
                    title={news.naslov}
                    date={news.datum_postavljanja}
                    text={news.sadrzaj}
                    category={news.kategorija}
                  />
        })
        :null
      }
      {error ? <div>error</div> : null}
    </div>
  );
}

export default News;
