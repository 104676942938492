import './App.css';
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';


import MainNavigation from './components/layout/mainNavigation';
import Footer from './components/layout/footer';
import SidePanel from './components/layout/sidePanel';

import HomePage from './components/pages/HomePage';
import NotFound from './components/pages/NotFoundPage';
import Admin from './components/pages/Admin';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Professors from './components/pages/Professors';
import ProfesorDetails from './components/layout/ProfesorDetails';
import OpenDoors from './components/pages/OpenDoors';
import Books from './components/pages/Books';
import ClassScheduleTime from './components/pages/ClassScheduleTime';
import Classes from './components/pages/Classes';
import TestSchedule from './components/pages/TestSchedule';
import Calendar from './components/pages/Calendar';
import PrirodniSmer from './components/pages/PrirodniSmer';
import DrustveniSmer from './components/pages/DrustveniSmer';
import ItSmer from './components/pages/ItSmer';
import Competitions from './components/pages/Competitions';
import Parlament from './components/pages/Parlament';
import Graduates from './components/pages/Graduates';
import DramaClub from './components/pages/DramaClub';
import Chor from './components/pages/Chor';
import Magazines from './components/pages/Magazines';
import Gimzeleni from './components/pages/Gimzeleni';
import InternationalCooperation from './components/pages/InternationalCooperation';
import JavneNabavke from './components/pages/JavneNabavke';
import NewsDetails from './components/layout/newsDetails';
import Pravilnici from './components/pages/Pravilnici';
import SportSection from './components/pages/SportSection';
import RockMarathon from './components/pages/RockMarathon';
import GimnazijskiIdol from './components/pages/GimnazijskiIdol';
import LinguisticSection from './components/pages/LinguisticSection';
import PredmetDetails from './components/layout/PredmetDetails';
import GradesCriteriums from './components/pages/gradesCriteriums';

import ImageSlideShow from './components/layout/ImageSlideShow';

function App() {
  // slike za prikaz na prvoj strani
  const images = [{src: "/naslovnaFotografije/skola2.jpg", alt: "skola", text: ""},
                  {src: "/naslovnaFotografije/slika1.jpg", alt: "skola", text: ""},
                  {src: "/naslovnaFotografije/slika2.jpg", alt: "skola", text: ""},
                  {src: "/naslovnaFotografije/slika3.jpg", alt: "skola", text: ""},
                  {src: "/naslovnaFotografije/slika4.jpg", alt: "skola", text: ""},
                  {src: "/naslovnaFotografije/slika5.jpg", alt: "skola", text: ""},
                  {src: "/naslovnaFotografije/slika6.jpg", alt: "skola", text: ""}];

  return (
    <div className="backgroundDiv">
    <div className="App">
      <Router>
        <MainNavigation />
      <div className="container-fluid content">
          <div className="row noPaddingMargin">
            <Routes>
              <Route exact path="/" element={<ImageSlideShow images={images}/>} />
            </Routes>
          </div>
          <div className="row padding">
            <div className="col col-lg-9 col-md-10 col-sm-12 col-12">
              <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/onama" element={<About />} />
                <Route path='/kontakt' element={<Contact />} />
                <Route path='/profesori' element={<Professors />} />
                <Route path='/profesor/:profesorID' element={<ProfesorDetails />} />
                <Route path='/otvorenaVrata' element={<OpenDoors />} />
                <Route path='/udzbenici' element={<Books />} />
                <Route path='/rasporedZvonjenja' element={<ClassScheduleTime />} />
                <Route path='/rasporedCasova' element={<Classes />} />
                <Route path='/rasporedPisanihProvera' element={<TestSchedule />} />
                <Route path='/kalendar' element={<Calendar />} />
                <Route path='/prirodniSmer' element={<PrirodniSmer />} />
                <Route path='/drustveniSmer' element={<DrustveniSmer />} />
                <Route path='/itSmer' element={<ItSmer />} />
                <Route path='/takmicenja' element={<Competitions />} />
                <Route path='/ucenickiParlament' element={<Parlament />} />
                <Route path='/maturanti' element={<Graduates />} />
                <Route path='/dramskiKlub' element={<DramaClub />} />
                <Route path='/hor' element={<Chor />} />
                <Route path='/skolskiCasopisi' element={<Magazines />} />
                <Route path='/gimzeleni' element={<Gimzeleni />} />
                <Route path='/medjunarodnaSaradnja' element={<InternationalCooperation />} />
                <Route path='/javneNabavke' element={<JavneNabavke />} />
                <Route path='/pravilnici' element={<Pravilnici />} />
                <Route path='/vesti/:newsID' element={<NewsDetails />} />
                <Route path='/sport' element={<SportSection />} />
                <Route path='/idol' element={<GimnazijskiIdol />} />
                <Route path='/rockMaraton' element={<RockMarathon />} />
                <Route path='/lingvistickaSekcija' element={<LinguisticSection />} />
                <Route path='/opredmetu/:predmetID' element={<PredmetDetails />} />
                <Route path='/kriterijumiOcenjivanja' element={<GradesCriteriums />} />
                <Route path="*" element={<NotFound />} />

              </Routes>
            </div>
            <div className="col col-lg-3 col-md-2 col-sm-12 col-12">
              <SidePanel />
            </div>
          </div>
        </div>
        <Footer />
      </Router>
    </div>
    </div>
  );
}

export default App;
